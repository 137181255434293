import {Routes, Route} from "react-router";
import {Studio} from "./pages/Studio";
import {Dashboard} from "./pages/Dashboard";
import {Products} from "./pages/Products";
import {UserAccount} from "./pages/UserAccount";
import {Home} from "./pages/Home";
import {Landing} from "./pages/Landing";
import {useAuthStore} from "@krfts/app-state";
import {ServersDown} from "./pages/ServersDown";
import {useQuery} from "@tanstack/react-query";
import {getStatus} from "@krfts/app-data";

function App() {

    const user = useAuthStore((state) => state.currentUser);
    const betaAuthorized = useAuthStore((state) => state.betaAuthorized);

    const { data } = useQuery(
        {
            queryKey: ['status'],
            queryFn: () => getStatus()
        })

    // Manual toggle to show servers down message
    if (data && data.servers_down && process.env.NODE_ENV !== `development`) {
        return <ServersDown/>
    }

    if (!user && !betaAuthorized && !localStorage.getItem("betaAuth")) {
        return <Landing/>
    }

    return (
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/products" element={<Products/>}/>
            <Route path="/products/:id" element={<Products/>}/>
            <Route path="/studio" element={<Studio/>}/>
            <Route path="/account" element={<UserAccount/>}/>
            <Route path="/dashboard" element={<Dashboard/>}/>
            <Route path="/landing" element={<Landing/>}/>
        </Routes>

    );
}

export default App;
