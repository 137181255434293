import {Button, ButtonGroup, Text} from '@chakra-ui/react'
import {NavLink} from "react-router";

export const NavButtons = () => {
    return (
        <ButtonGroup
            size="md"
            variant="text"
            colorScheme="gray"
            spacing="8"
            display={{base: 'none', lg: 'flex'}}
        >
            {/*<NavLink to="/dashboard" end>*/}
            {/*    <Button>DASHBOARD</Button>*/}
            {/*</NavLink>*/}
            <NavLink to="/products" end>
                <Button>MY ASSETS</Button>
            </NavLink>
            <NavLink to="/studio" end>
                <Button><Text bgGradient="linear(to-r, yellow.300, pink.300)" bgClip='text'>STUDIO</Text></Button>
            </NavLink>
        </ButtonGroup>
    )
}