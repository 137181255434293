import {Box, Button, VStack} from '@chakra-ui/react'
import {ProductGrid} from "./product-grid";
import {TextBox} from "@krfts/core-text-box";

export const ProductList = ({setShowNewProductModal, products}) => {

    return (
        <>
            <VStack w='100%' mt={8} px={{base:4, md:20}} spacing={12}>
                <Box w={{base:'80%', md:'60%'}}>
                    <TextBox
                        text='This is a list of your assets. Create a new one to generate images and videos for or view what you have created for your existing assets.'
                    />
                </Box>
                <VStack spacing={2}>
                    <Button bg='yellow.300' _hover={{bg: 'yellow.100'}}
                            onClick={() => setShowNewProductModal(true)}>+
                        New Asset</Button>
                    <ProductGrid products={products}/>
                </VStack>

            </VStack>
        </>
    )
}